@import './variables.scss';

p {
    font-family: $primaryfont;
}

ul {
    margin-top: 0;
}

li {
    margin-top: -0.5vh;
    margin-left: -4vw;
}

.header {
    width: 100vw;
    height: 28vh;
    background-image: url('./../assets/header.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.logo {
    height: 8vh;
    position: absolute;
    top: 6vh;
    right: 5vw;
}

.contentDiv {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column
}

.projectPhoto {
   width: 95vw;
}

.websitePhoto {
    width: 100%;
    border: 1px solid $logogreen;
    border-radius: 5px !important;
    margin-bottom: 0 !important;
}

.displayPhoto {
    width: 100%;
    margin-top: -5vh;
    margin-bottom: -5vh;
    background-image: url('./../assets/reactbackground.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.webpageDisplayPhoto {
    width: 80%;
    margin-bottom: 2vh;
    background-image: url('./../assets/reactbackground.png');
    background-repeat: no-repeat;
    background-size: contain;
    border: 3px solid $yellow;
}

.photoDisplay {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: scroll;
    flex-direction: row;
}

.link {
    text-decoration: none !important;
    color: #c29a19;
    text-shadow: 0 0 15px #000;
}

.projectLink {
    font-size: 3vh !important;
    margin-top: -10vh !important;
    margin-bottom: 6vh !important;
    margin-left: 6vw !important;
}

.websiteLink {
    font-size: 3vh !important;
    margin-top: 0 !important;
    margin-bottom: 1vh !important;
    text-align: center;
}

.phoneLink {
    font-size: 3vh !important;
    margin-top: -8vh !important;
    margin-bottom: 2vh !important;
    margin-left: 23vw !important;
}

.projectDescription {
    font-size: 2.2vh !important;
    font-weight: 300 !important;
    margin-top: 0 !important;
    margin-bottom: 3vh !important;
}

.projectSubheading {
    font-size: 2.5vh !important;
    margin-top: 0 !important;
    margin-bottom: 1vh !important;
}

.buttonContainer {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.seeMoreButton {
    background-color: $yellow;
    font-family: $primaryfont;
    font-size: 2.5vh !important;
    color: $darkgrey;
    border: 1px solid $yellow;
    border-radius: 5px;
    padding: 0.1vh 0.8vh;
    text-decoration: none !important;
    width: 35%;
    margin: 0 auto !important;    
}

.closeButton {
    color: $logogreen;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 6vw;
    border: none;
    background-color: transparent;
}

.projectTitle {
    font-family: $titlefont;
    font-size: 8vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
    color: $darkgrey;
    margin: 2vh 2vw 5vh 2vw;
} 

.footerDiv {
    //justify-content: flex-end;
    height: 5vh;
    background-color: rgb(18, 18, 18, 0.85);
    color: $yellow;
    text-align: center;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    margin-top: auto;
}

.footerText {
    margin: 1vh;
}

.headshot {
    width: 26vw;
    height: 26vw;
    border: 4px solid #fff;
    border-radius: 26vw;
    margin-top: -13vw;
}

.bioDiv {
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('./../assets/reactbackground.png');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100vh;
    display: flex;
    flex-direction: column
}

.bioContainer {
    margin: 10vw 8vw;
    padding: 0 6vw;
    border: 6px double $logogreen;
    background-color: #fff;
} 

.contactContainer {
    text-align: center;    
} 

.education {
    margin: 4vh 0 -2vh;
    font-family: $primaryfont;
    color: $darkgrey;
    opacity: 40%;
    font-weight: 600;
}

.contactLink {
    text-decoration: none;
    color: $yellow;
}

.innerLink {
    text-decoration: none;
    color: $darkgrey;
    font-weight: 600;
    border: 3px solid $yellow;
    border-radius: 5px;
    width: fit-content;
    padding: 3px 12px;
    margin: 0 auto;
}

.row {
    display: flex;
    justify-content: center;
    background-color: transparent;
    margin: 0;
}

.navButton {
    width: 18vw;
    height: 12vw;
    background-color: $yellow;
    border: 2px solid $yellow;
    border-radius: 16vw;
    text-align: center;
    color: $darkgrey;
    margin: -9vw 2vw 5vw 2vw;
    padding-top: 6vw;
    font-weight: 400;
    font-size: 4.5vw;

    // &:hover {
    //     background-color: #fff;
    //     border-color: #fff;
    // }

}

.activeNavButton {
    width: 26vw;
    height: 6vw;
    padding: 8vw 1vw 0 1vw;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 28vw 28vw 0 0;
    text-align: center;
    color: $darkgrey;
    margin: -14vw 2vw 5vw 2vw;
    font-weight: 600;
    font-size: 5.5vw;
}

.contact {
    font-family: $primaryfont;
    color: $darkgrey;
    opacity: 80%;
    font-weight: 600;
}

.emailLink {
    text-decoration: none;
    font-weight: 400;
    color: $logogreen;
}

// iPadMini
@media screen and (min-width: 768px) {
    li {
        margin-left: 0vw;
    }

    .logo {
        height: 8vh;
        position: absolute;
        top: 5vh;
        right: 15vw;
    }

    .projectLink {
        font-size: 4vh !important;
        margin-top: -15vh !important;
        margin-left: 7vw !important;
    }
    
    .websiteLink {
        font-size: 4vh !important;
    }
    
    .phoneLink {
        font-size: 4vh !important;
        margin-top: -12vh !important;
        margin-bottom: 4vh !important;
        margin-left: 29vw !important;
    }
    
    .projectDescription {
        font-size: 2.8vh !important;
        font-weight: 300 !important;
        margin-top: 0 !important;
    }
    
    .projectSubheading {
        font-size: 2.8vh !important;
        margin-top: 0 !important;
        margin-bottom: 1vh !important;
    }

    .headshot {
        width: 22vw;
        height: 22vw;
        border: 6px solid #fff;
        border-radius: 22vw;
        margin-top: -11vw;
    }
    
    .bioDiv {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
    .bioContainer {
        margin: 10vw 8vw 10vh;
        padding: 0 6vw;
        border: 6px double $logogreen;        
        font-size: 2.8vh;
    } 
    
    .contactContainer {      
        font-size: 2.8vh;
    } 

    .education {
        margin: 4vh 0 -2vh;
        font-family: $primaryfont;
        color: $darkgrey;
        opacity: 40%;
        font-weight: 600;
    }

    .activeNavButton {
        height: 6vw;
        padding-top: 8vw;
    }
}

// laptop
@media screen and (min-width: 1180px) {
    li {
        margin-left: -1vw;
    }

    td {
        vertical-align: top;
    }

    .header {
        height: 45vh;
    }

    .logo {
        height: 12vh;
        position: absolute;
        top: 4vh;
        right: 15vw;
    }

    .projectDescriptionRight {
        font-size: 2.8vh !important;
        font-weight: 300 !important;
        margin-top: 0 !important;
        margin-left: 20vw !important;
        margin-bottom: 18vh  !important;
    }

    .projectDescriptionLeft {
        font-size: 2.8vh !important;
        font-weight: 300 !important;
        margin-top: 0 !important;
        margin-bottom: 12vh  !important;
        width: 55% !important;
    }

    .projectDescriptionCenter {
        font-size: 2.8vh !important;
        font-weight: 300 !important;
        margin-top: -12vh !important;
        width: 55% !important;
        margin-left: 15vw !important;
    }

    .projectLink {
        margin-top: -55vh !important;
        margin-left: 16vw !important;
    }
    
    .phoneLink {
        margin-top: -40vh !important;
        margin-bottom: 2vh !important;
        margin-left: 38vw !important;
    }

    .seeMoreButton {
        font-size: 2.8vh !important;
        width: 20%;
        clear: both;
        //margin: 0 auto !important;    
    }

    .displayPhoto {
        width: 30%;
        margin-top: -5vh;
        margin-bottom: -5vh;
        background-image: url('./../assets/reactbackground.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .photoDisplay {
        width: 100%;
        overflow-x: scroll;
        flex-direction: row;
    }

    .projectTitle {
        font-size: 4vw;
    } 

    .bioContainer {
        margin: 5vw 20vw 10vh;
        padding: 0 6vw;
        border: 6px double $logogreen;        
        font-size: 2.8vh;
    } 

    .navButton {
        width: 10vw;
        height: 6.5vw;
        border-radius: 8vw;
        margin: -5vw 5vw 5vw 5vw;
        padding-top: 3.5vw;
        font-size: 2.2vw;    
    }
    
    .activeNavButton {
        width: 20vw;
        height: 4vw;
        border-radius: 20vw 20vw 0 0;
        margin: -10vw 2vw 5vw 2vw;
        padding: 6vw 0 0 0;
        font-size: 4vw;
    }

    .headshot {
        width: 12vw;
        height: 12vw;
        border: 6px solid #fff;
        border-radius: 22vw;
        margin-top: -6vw;
    }
}