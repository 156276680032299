@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stint+Ultra+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karantina&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Inline+Text:wght@600&display=swap');

// colours
$logogreen: #003d3d;
//actual logo color: #006666;
$yellow: #c29a19;
$darkgrey: #121212;

// fonts
$primaryfont: 'Akshar', sans-serif;
$titlefont: 'Big Shoulders Inline Text', cursive;